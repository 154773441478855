// CORE-4538
const enableStatisticsChoices = [
    {
        id: 'statistics_dw_smart_ai',
        name: 'Enable DW Statistics - Smart AI Cycle',
    },
    {
        id: 'statistics_dw_smart_grid',
        name: 'Enable DW Statistics - Smart Grid',
    },
    { id: 'statistics_dw_eco_delay', name: 'Enable DW Statistics - Eco Delay' },
    {
        id: 'statistics_dw_instant_consumption',
        name: 'Enable DW Statistics - Instant Consumption',
    },
    {
        id: 'statistics_dw_auto_cycle_efficiency',
        name: 'Enable DW Statistics - Auto cycle efficiency',
    },
    {
        id: 'statistics_dw_water_efficiency',
        name: 'Enable DW Statistics - Water efficiency',
    },
    {
        id: 'statistics_dw_temperature_trend',
        name: 'Enable DW Statistics - Temperature Trend',
    },
    {
        id: 'statistics_dw_temperature_usage',
        name: 'Enable DW Statistics - Temperature Usage',
    },
    {
        id: 'statistics_dw_programs_counter',
        name: 'Enable DW Statistics - Programs counter',
    },
    {
        id: 'statistics_dw_most_used_programs',
        name: 'Enable DW Statistics - Most used programs',
    },
    {
        id: 'statistics_td_smart_ai',
        name: 'Enable TD Statistics - Smart AI Cycle',
    },
    {
        id: 'statistics_td_smart_grid',
        name: 'Enable TD Statistics - Smart Grid',
    },
    { id: 'statistics_td_eco_delay', name: 'Enable TD Statistics - Eco Delay' },
    {
        id: 'statistics_td_instant_consumption',
        name: 'Enable TD Statistics - Instant Consumption',
    },
    {
        id: 'statistics_td_load_efficiency',
        name: 'Enable TD Statistics - Load efficiency',
    },
    {
        id: 'statistics_td_programs_counter',
        name: 'Enable TD Statistics - Programs counter',
    },
    {
        id: 'statistics_td_temperature_usage',
        name: 'Enable TD Statistics - Temperature Usage',
    },
    {
        id: 'statistics_td_most_used_programs',
        name: 'Enable TD Statistics - Most used programs',
    },
    {
        id: 'statistics_wm_wd_smart_grid',
        name: 'Enable WM-WD Statistics - Smart Grid',
    },
    {
        id: 'statistics_wm_wd_eco_delay',
        name: 'Enable WM-WD Statistics - Eco Delay',
    },
    {
        id: 'statistics_wm_wd_instant_consumption',
        name: 'Enable WM-WD Statistics - Instant Consumption',
    },
    {
        id: 'statistics_wm_wd_loading_percentage',
        name: 'Enable WM-WD Statistics - Loading percentage',
    },
    {
        id: 'statistics_wm_wd_smart_ai',
        name: 'Enable WM-WD Statistics - Smart AI Cycle',
    },
    {
        id: 'statistics_wm_wd_temperature_usage',
        name: 'Enable WM-WD Statistics - Temperature usage',
    },
    {
        id: 'statistics_wm_wd_programs_counter',
        name: 'Enable WM-WD Statistics - Programs counter',
    },
    {
        id: 'statistics_wm_wd_most_used_programs',
        name: 'Enable WM-WD Statistics - Most used programs',
    },
];
// DW legacy options about statistics, kept for backward compatibility
const legacyDWDisableStatisticsChoices = [
    { id: 'dw_statistics', name: 'Disable DW Statistics (Legacy)' },
    {
        id: 'dw_statistics_instant_consumption',
        name: 'Disable DW Statistics - Instant Consumption (Legacy)',
    },
    {
        id: 'dw_statistics_auto_cycle_efficiency',
        name: 'Disable DW Statistics - Auto Cycle Efficiency (Legacy)',
    },
    {
        id: 'dw_statistics_water_efficiency',
        name: 'Disable DW Statistics - Water Efficiency (Legacy)',
    },
    {
        id: 'dw_statistics_temperature_usage',
        name: 'Disable DW Statistics - Temperature Usage (Legacy)',
    },
    {
        id: 'dw_statistics_temperatures_trend',
        name: 'Disable DW Statistics - Temperatures Trend (Legacy)',
    },
    {
        id: 'dw_statistics_programs_counter',
        name: 'Disable DW Statistics - Programs Counter (Legacy)',
    },
    {
        id: 'dw_statistics_most_used_programs',
        name: 'Disable DW Statistics - Most Used Programs (Legacy)',
    },
];
const sectionChoices = [
    // DISABLE
    { id: 'enrollment', name: 'Disable Enrollment' },
    { id: 'demo', name: 'Disable Demo' },
    { id: 'chatbot', name: 'Disable Chatbot' },
    { id: 'inventory', name: 'Disable Label Inventory' },
    { id: 'inventory_wine_scan', name: 'Disable Inventory Wine Scan' },
    { id: 'wine_food_pairings', name: 'Disable Wine Food Pairings' },
    { id: 'guided_washing', name: 'Disable Guided Washing' },
    { id: 'snap_wash', name: 'Disable Snap & Wash' },
    { id: 'snap_wash_dw', name: 'Disable Snap & Wash Dishwasher' },
    { id: 'hints', name: 'Disable Hints & Tips' },
    { id: 'extra_cycles_disabled', name: 'Disable Extra Cycles' },
    {
        id: 'advanced_drink_assistant',
        name: 'Disable Advanced Drink Assistant',
    },
    { id: 'food_locator', name: 'Disable Food Locator' },
    { id: 'proactive_temperature', name: 'Disable Proactive Temperature All' },
    {
        id: 'proactive_temperature_shop',
        name: 'Disable Proactive Temperature Shop',
    },
    {
        id: 'proactive_temperature_weather',
        name: 'Disable Proactive Temperature Weather',
    },
    {
        id: 'proactive_temperature_habits',
        name: 'Disable Proactive Temperature Habits',
    },
    { id: 'inspire_me', name: 'Disable Inspire Me' },
    { id: 'shopping_list', name: 'Disable Shopping List' },
    { id: 'recipes', name: 'Disable Recipes' },
    { id: 'smart_drink_assistant', name: 'Disable Smart Drink Assistant' },
    { id: 'my_profile_temperature', name: 'Disable My Profile Temperature' },
    { id: 'my_inventory', name: 'Disable My Inventory' },
    { id: 'smart_check_up', name: 'Disable Smart Check Up' },
    { id: 'check_up', name: 'Disable Check Up' },
    { id: 'inside_view', name: 'Disable Inside View' },
    { id: 'door_opening_statistics', name: 'Disable Door Opening Statistics' },
    { id: 'allergic_profile', name: 'Disable Allergic Profile' },
    {
        id: 'gps_end_of_cycle_notification',
        name: 'Disable Geolocalised end-of-cycle notification',
    },
    { id: 'assisted_recipes', name: 'Disable Assisted Recipes' },
    ...legacyDWDisableStatisticsChoices,
    { id: 'dish_placer', name: 'Disable Dish Placer' },
    { id: 'snap_cook', name: 'Enable Snap & Cook' },
    // ENABLE
    { id: 'double_pairing_hidden', name: 'Enable single pairing' },
    { id: 'epp_enabled', name: 'Enable EPP' },
    { id: 'quickPair_enabled', name: 'Enable Quick pair' },
    { id: 'enroll_under_norms', name: 'Enable Enrollment under norms' },
    { id: 'snap_and_dry', name: 'Enable Snap & Dry' },
    { id: 'my_zone_pro_enabled', name: 'Enable My Zone Pro' },
    { id: 'my_zone_enabled', name: 'Enable My Zone' },
    { id: 'new_special_features_enabled', name: 'Enable New Special Features' },
    { id: 'visual_my_zone_pro_enabled', name: 'Enable Visual My Zone Pro' },
    { id: 'visual_my_zone_enabled', name: 'Enable Visual My Zone' },
    { id: 'tdCrossSell', name: 'Enable Purchase Proposal TD' },
    { id: 'visual_tricks', name: 'Enable Visual Tricks' },
    // For old app versions, it directly controls the smartgrid graphic, since CORE-4535 it is the master setting for eco delay and smart grid
    { id: 'smart_energy', name: 'Enable Smart Energy' },
    { id: 'quick_set', name: 'Enable Quick Set' },
    { id: 'quick_set_help', name: 'Enable Quick Set Help' },
    { id: 'energy_and_sustainability', name: 'Energy & Sustainability' },
    {
        id: 'energy_and_sustainability_holiday_mode',
        name: 'Enable Energy & Sustainability - Holiday Mode',
    },
    {
        id: 'energy_and_sustainability_door_openings',
        name: 'Enable Energy & Sustainability - Door Openings',
    },
    {
        id: 'energy_and_sustainability_tips_hints',
        name: 'Enable Energy & Sustainability - Tips & Hints',
    },
    // COOL-3935, COOL-3933 Fridge Blackout Alert
    { id: 'fridge_blackout_alert', name: 'Enable Fridge Blackout Alert' },
    { id: 'enabled_local_OTA', name: 'Enable Local OTA' },
    { id: 'enable_new_program_detail', name: 'Enable New Program Detail' },
    // CORE-4535
    ...enableStatisticsChoices,
];
module.exports = {
    sectionChoices,
};

import { makeStyles } from '@material-ui/core/styles';
import AssistantIcon from '@material-ui/icons/Assistant';
import {
    ContainerFieldsList,
    CustomBreadcrumbs,
    CustomSelectArrayInput,
    CustomSelectInput,
} from 'candy-commons-components';
import React, { useState } from 'react';
import {
    BooleanInput,
    DateInput,
    Edit,
    FormDataConsumer,
    SimpleForm,
    TextInput,
    required,
    useTranslate,
} from 'react-admin';
import CustomToolbar from '../../components/CustomToolbar';
import APPLIANCE_TYPES from '../../data/applianceType.json';
import PAGE_CONTENTS from '../../data/newFeaturesBannerPageOptions.json';
import BRANDS from '../../data/brand.json';
const NewFeaturesBannerEdit = ({ permissions = {}, ...props }) => {
    const classes = useStyles();
    const [allApplianceFlag, setAllApplianceFlag] = useState(false);
    const [allBrandFlag, setAllBrandFlag] = useState(false);
    const { id } = props;
    const translation = useTranslate();
    const isEditDisabled = !permissions?.['new-features-banner']?.update;
    const breadcrumbsData = [
        {
            text: translation('resources.new-features-banner.name'),
            url: 'new-features-banner',
            icon: <AssistantIcon />,
        },
        {
            text: id,
            url: '',
        },
    ];
    const handleAllAppliance = (e) => setAllApplianceFlag(e.target.checked);
    const handleAllBrand = (e) => setAllBrandFlag(e.target.checked);
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <Edit className={classes.reverseButtonSave} {...props}>
                <SimpleForm
                    toolbar={
                        <CustomToolbar
                            permissions={permissions}
                            isEditDisabled={isEditDisabled}
                        />
                    }
                >
                    <ContainerFieldsList>
                        <TextInput
                            label="Title"
                            source="title"
                            validate={required()}
                        />
                        <CustomSelectInput
                            label="Page content"
                            source="pageContent"
                            validate={required()}
                            choices={PAGE_CONTENTS}
                        />
                        <DateInput
                            source="validFrom"
                            label="Valid from"
                            validate={required()}
                        />
                        <DateInput
                            source="validTo"
                            label="Valid to"
                            validate={required()}
                        />
                        <TextInput
                            label="Image 24x24"
                            source="image"
                            validate={required()}
                        />
                        <TextInput label="Deeplink" source="deeplink" />
                        {/* <TextInput
                            label="Description"
                            source="description"
                            validate={required()}
                        />
                        <TextInput label="Color es:#000" source="color" /> */}
                    </ContainerFieldsList>
                    <ContainerFieldsList>
                        <FormDataConsumer>
                            {({ formData }) => (
                                <>
                                    {!formData.AllAppliance &&
                                        !allApplianceFlag && (
                                            <CustomSelectArrayInput
                                                label="Appliance types"
                                                source="applianceType"
                                                choices={APPLIANCE_TYPES}
                                                validate={required()}
                                                disabled={
                                                    allApplianceFlag ||
                                                    isEditDisabled
                                                }
                                            />
                                        )}
                                    <BooleanInput
                                        label="All appliance"
                                        source="isAllAppliance"
                                        disabled={
                                            formData.NoAppliance ||
                                            isEditDisabled
                                        }
                                        onClick={handleAllAppliance}
                                    />
                                </>
                            )}
                        </FormDataConsumer>
                    </ContainerFieldsList>
                    <ContainerFieldsList>
                        <FormDataConsumer>
                            {({ formData }) => (
                                <>
                                    {!formData.allBrandFlag &&
                                        !allBrandFlag && (
                                            <CustomSelectArrayInput
                                                label="Brand"
                                                source="brand"
                                                validate={required()}
                                                choices={BRANDS.filter(
                                                    (br) => br.id !== 'all'
                                                )}
                                            />
                                        )}
                                    <BooleanInput
                                        label="All brand"
                                        initialValue={false}
                                        source="isAllBrand"
                                        onClick={handleAllBrand}
                                    />
                                </>
                            )}
                        </FormDataConsumer>
                    </ContainerFieldsList>
                </SimpleForm>
            </Edit>
        </>
    );
};
const useStyles = makeStyles({
    reverseButtonSave: {
        '& .MuiToolbar-root>div': {
            flexDirection: 'row-reverse',
        },
    },
});
export default NewFeaturesBannerEdit;
